import api from "./axios_service.js";

export async function fetchMessages(userTo) {
  const response = await api.get(`/list-messages/${userTo}`);
  return response.data.result;
}

export async function addMessage(message) {
  const response = await api.post("/messages/add", message);
  return response;
}
