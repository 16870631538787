<template>
  <v-container fluid>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-chat</v-icon>
        Chat
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="rounded pa-4" v-if="networkings.length">
        <v-row no-gutters class="accent">
          <v-col cols="3" class="pa-3">
            <v-card outlined>
              <v-list nav>
                <v-list-item-group
                  v-model="menuIndex"
                  color="primary"
                  @change="setUserTo"
                >
                  <v-list-item
                    v-for="(item, index) in networkings"
                    :key="index"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="item.avatar"
                        :src="item.avatar"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        :src="require('@/assets/avatar_placeholder.png')"
                        contain
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">
                        {{ item.nome }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            cols="9"
            :style="`max-height: calc(${chatBoxSize}px)`"
            class="pa-3"
          >
            <v-card
              v-if="messages.length"
              outlined
              tile
              class="pa-3"
              :height="messageBoxSize"
              style="overflow: auto"
              ref="boxMessages"
            >
              <v-sheet
                v-for="(message, index) in messages"
                :key="index"
                class="d-flex flex-column mb-3"
                :class="{
                  'ml-auto text-right align-end': message.from == user_id,
                }"
                max-width="75%"
              >
                <v-sheet
                  width="fit-content"
                  :color="message.from == user_id ? 'accent' : '#F5F5F5'"
                  class="py-2 px-3 rounded flex-grow-0"
                >
                  {{ message.content }}
                </v-sheet>
                <v-sheet class="text-caption">
                  {{ message.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                </v-sheet>
              </v-sheet>
            </v-card>
            <v-card v-else outlined tile :loading="loadingMessages">
              <v-card-text class="py-6 text-center">
                Nenhuma mensagem encontrada...
              </v-card-text>
            </v-card>
            <v-card tile flat color="accent" height="120px" class="pt-3">
              <v-text-field
                class="rounded white"
                v-model="message"
                placeholder="escreva aqui sua mensagem..."
                outlined
                rounded
                hide-details=""
                append-icon="mdi-send"
                @keydown.enter="addComentario()"
                @click:append="addComentario()"
              ></v-text-field>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        Adicione um networking para iniciar o chat...
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { index as fetchNetworkings } from "@/api/admin/networking.js";
import { fetchMessages, addMessage } from "@/api/messages/messages.js";
import { mapState } from "vuex";
// import { format } from "date-fns";

export default {
  name: "messages",

  data() {
    return {
      loading: false,
      loadingMessages: false,
      menuIndex: 0,
      networkings: [],
      message: "",
      messages: [],
      userTo: null,
      interval: "",
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    windowSize() {
      return { x: window.innerWidth, y: window.innerHeight };
    },
    chatBoxSize() {
      return this.windowSize.y - 200;
    },
    messageBoxSize() {
      return this.chatBoxSize - 200;
    },
  },

  methods: {
    async setUserTo() {
      this.userTo = this.networkings[this.menuIndex].networking_user_id;

      await this.getMessages();
    },

    scrollToEnd() {
      this.$nextTick(() => {
        this.$refs.boxMessages.$el.scrollTop =
          this.$refs.boxMessages.$el.scrollHeight;
      });
    },

    async getMessages() {
      this.loadingMessages = true;
      this.messages = await fetchMessages(this.userTo);
      this.loadingMessages = false;
    },

    addComentario() {
      let message = {};
      message.content = this.message;
      message.to = this.userTo;

      addMessage(message).then(async (response) => {
        if (response.status === 201) {
          await this.getMessages();
          this.scrollToEnd();
          this.message = "";
        }
      });
    },

    reloadMensagens() {
      const self = this;
      this.interval = setInterval(() => {
        self.getMessages();
        console.log("mensagens atualizadas!");
      }, 10000);
    },
  },

  async mounted() {
    try {
      this.loading = true;

      this.networkings = await fetchNetworkings(this.user_id);
      await this.setUserTo();
      this.scrollToEnd();
      this.reloadMensagens();

      // this.$echo
      //   .private(`user.${this.user_id}`)
      //   .listen(".SendMessage", (response) => {
      //     if (this.userTo == response.message.from) {
      //       this.messages.push(response.message);
      //       this.scrollToEnd();
      //     }
      //   });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },

  beforeDestroy() {
    // this.$echo.channel("posts").stopListening(".postCreate");
    // this.$echo.channel("posts").unsubscribe();
    clearInterval(this.interval);
  },
};
</script>

<style></style>
